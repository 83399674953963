export async function getPOIById(id) {
  return callApi("CanalTPBookmarkBundle_get_poi", { id });
}

export function calculateDifferenceForNextDepartures(times) {
  // Get the current date and time
  const currentDate = new Date();
  const currentHours = currentDate.getHours().toString().padStart(2, "0");
  const currentMinutes = currentDate.getMinutes().toString().padStart(2, "0");
  const currentTime = `${currentHours}:${currentMinutes}`;

  // Create an object to store the results
  const results = { times: [], data_freshness: times["data_freshness"] };
  // Loop through each time in the input array
  if (times["times"]) {
    for (let i = 0; i < times["times"].length; i++) {
      const time = times["times"][i]; // access the i-th element of the 'times' array
      // Split the current time and the time from the input array into hours and minutes
      const [hour1, minute1] = currentTime.split(":");
      const [hour2, minute2] = time.split(":");

      // Calculate the difference between the current time and the time from the input array in minutes
      const diffInMinutes =
          (parseInt(hour2) - parseInt(hour1)) * 60 +
          (parseInt(minute2) - parseInt(minute1));

      // If the difference is less than 60 minutes, push the absolute difference in minutes to the results array
      if (Math.abs(diffInMinutes) < 60) {
        results["times"].push(`${Math.abs(diffInMinutes)}`);
      }
    }
  }
  // Return the results object
  return results;
}

export function getAllNextDepartures(stopArea) {
  return callApiLinesById(stopArea.data.lineId)
      .then((data) => {
        let line = data.lines[0];

        const departuresPromises = line.routes.map(async (route, i) => {
          return getNextDeparturesForStopArea(
              stopArea.data.stopAreaId,
              line.id,
              route.id
          ).then((departures) => {
            let result = calculateDifferenceForNextDepartures(departures[0]);
            const directionName = line.routes[i].name;

            return {
              nextPassages: result.times ?? [],
              direction: directionName,
            };
          });
        });

        return Promise.all(departuresPromises);
      })
      .catch((error) => {
        console.error("Error getting departures:", error);
      });
}

export async function getNextDeparturesForStopArea(
  stop_area_id,
  line_id,
  route_id
) {
  let nextDepartures = [];
  await JKisio.ajax({
    url: Routing.generate("journey_departures", {
      type_product:
        window.Kisio.type_product !== undefined
          ? window.Kisio.type_product
          : "",
      line_id: line_id,
      stop_area_id: stop_area_id,
      route_id: route_id,
      stop_type: "stop_areas",
    }),
    dataType: "json",
    success: function (result) {
      nextDepartures.push(result);
    },
  });

  return nextDepartures;
}

async function callApi(routeName, params) {
  let result = [];
  await JKisio.ajax({
    url: Routing.generate(routeName, {
      type_product:
        window.Kisio.type_product !== undefined
          ? window.Kisio.type_product
          : "",
      ...params,
    }),
    success: function (data) {
      result = data;
    },
    error: function (error) {
      console.log(error);
    },
  });

  return result;
}

export async function callApiLinesById(id) {
  return callApi("r_public_transport_objects_filtered", {
    public_transport_object_type: "lines",
    public_transport_object_id: id,
    action: "lines",
  });
}

export async function callApiJourneyFavorite(link) {
  return callApi("favorites_journey_solution", {
    link: link,
    index: 0,
  });
}
