import { Component } from "../componant";
import { MenuComponent } from "./menu-component";
import { BookmarkClientFactory } from "../../client/bookmark-client-factory";
import { NoBookmarkTabContentComponent } from "./no-bookmark-tab-content-component";
import { getPOIById, getAllNextDepartures } from "../../api";
import {addSimplebarEventListener} from "../../simplebar";
import {generateLogoFromConfig} from "pnp_core_bundle/modules/utils/logoManagement";
import {ButtonDepartureArrival} from "journey_bundle/modules/buttonDepartureArrival/builder.js";

export function TransportTabContentComponent(props) {
  Component.call(this, props);
  this.classname = "TransportTabContentComponent";
  this.bookmarkCollections = [];
}


TransportTabContentComponent.prototype = Object.create(Component.prototype);

TransportTabContentComponent.prototype.shouldBeFocusOn = true;

TransportTabContentComponent.prototype.render = function () {
  const container = document.createElement("div");
  container.autofocus = true;
  container.classList.add("transport-tab-content");

  let client = new BookmarkClientFactory().createClient();
  this.bookmarkCollections = client.getSync();

  let componantBookmarkCollection = this.bookmarkCollections.filter(
    (bookmark) => {
      return (
        bookmark.type === "stop_area_lines_direction" ||
        (bookmark.type === "poi" &&
          bookmark.data.poiType.includes("bicycle_rental")) ||
        (bookmark.type === "poi" &&
          bookmark.data.poiType.includes("amenity:parking"))
      );
    }
  );

  if (componantBookmarkCollection.length === 0) {
    const noBookmarkTabContent = new NoBookmarkTabContentComponent();
    noBookmarkTabContent.mount(container);
    return container;
  }

  const stopSection = this.renderStopsSection();
  const vlsStationsSection = this.renderVlsStationsSection();
  const parkingSection = this.renderParkingPRSection();

  container.appendChild(stopSection);
  container.appendChild(vlsStationsSection);
  container.appendChild(parkingSection);

  addSimplebarEventListener(this.classname);
  return container;
};

TransportTabContentComponent.prototype.renderStopsSection = function () {
  const section = document.createElement("section");
  section.classList.add("section-stops");

  const title = document.createElement("h3");
  title.textContent = "Arrêts";

  const container = document.createElement("div");
  container.classList.add("stops-container");

  let stopAreas = this.bookmarkCollections.filter((bookmark) => {
    return bookmark.type === "stop_area_lines_direction";
  });

  section.appendChild(title);
  section.appendChild(container);

  if (stopAreas.length === 0) {
    section.style.display = "none";
    return section;
  }

  let item = null;

  stopAreas.forEach((stopArea, index) => {
    // Premier élément de la section Arrêts
    item = this.renderItemStopArea(stopArea);
    container.appendChild(item);
  });

  return section;
};

TransportTabContentComponent.prototype.renderRoutesSection = function (routes) {
  const section = document.createElement("section");
  section.classList.add("section-routes");

  const title = document.createElement("h3");
  section.appendChild(title);

  routes.forEach((route) => {
    const routeDiv = document.createElement("div");
    routeDiv.classList.add("routes");

    const directionSpan = document.createElement("span");
    directionSpan.classList.add("direction");
    directionSpan.textContent = route.direction;
    routeDiv.appendChild(directionSpan);

    if (route.nextPassages.length > 0) {
      const nextPassagesSpan = document.createElement("span");
      nextPassagesSpan.classList.add("next"); // Prochains départs indisponibles

      nextPassagesSpan.innerHTML += ` ${route.nextPassages.join(", ")}mn`;
      routeDiv.appendChild(nextPassagesSpan);
    } else {
      const notAvalaible = document.createElement("span");
      notAvalaible.classList.add("not-avalaible-next"); // Prochains départs indisponibles
      notAvalaible.textContent = "Prochains départs indisponibles";

      routeDiv.appendChild(notAvalaible);
    }

    section.appendChild(routeDiv);
  });

  return section;
};

TransportTabContentComponent.prototype.renderItemStopArea = function (stopArea) {
  const item = document.createElement("div");
  item.classList.add("item", "deletable");

  const header = document.createElement("div");
  header.classList.add("header");

  const commercialModeDiv = document.createElement("div");
  commercialModeDiv.classList.add("left", "kisio-line-code");
  const imgCM = document.createElement("img");
  imgCM.classList.add("picto_line");
  imgCM.src = this.getModePictoSrc(stopArea.data.mode);
  imgCM.alt = stopArea.data.mode;
  commercialModeDiv.appendChild(imgCM);

  const lineNumberSpan = document.createElement("span");
  lineNumberSpan.classList.add("left", "kisio-line-code");
  lineNumberSpan.textContent = stopArea.data.code;
  lineNumberSpan.style.color = `#${stopArea.data.codeColor}`;
  lineNumberSpan.style.background = `#${stopArea.data.codeBgColor}`;

  let lineNumberHtml = generateLogoFromConfig('lines', stopArea.data.lineId, lineNumberSpan.outerHTML, {classname: "left kisio-img-line-code"});

  const stopNameSpan = document.createElement("span");
  stopNameSpan.classList.add("left", "stop-name");
  stopNameSpan.textContent = stopArea.data.name;

  header.appendChild(commercialModeDiv);
  header.innerHTML += lineNumberHtml; // Ajouter lineNumberHtml en tant que HTML
  header.appendChild(stopNameSpan);
  const rightDiv = new MenuComponent({
    id: stopArea.id,
    componentUpdate: this.update.bind(this),
  });
  rightDiv.mount(header);

  const contentDiv = document.createElement("div");
  contentDiv.classList.add("content");

  const loaderDiv = document.createElement("div");
  loaderDiv.classList.add("loader-content");

  let loadingImg = this.getLoadingElement();
  loaderDiv.appendChild(loadingImg);
  contentDiv.appendChild(loaderDiv);

  getAllNextDepartures(stopArea).then((allDepartures) => {
    const routesSection = this.renderRoutesSection(allDepartures);
    while (contentDiv.firstChild) {
      contentDiv.removeChild(contentDiv.firstChild);
    }
    contentDiv.appendChild(routesSection);
  });

  item.appendChild(header);
  item.appendChild(contentDiv);

  return item;
};

TransportTabContentComponent.prototype.getModePictoSrc = function (commercialModeId) {
  let mode = commercialModeId.split(":")[1].toLowerCase();
  let img = window.Kisio.modes_img[mode];
  if (typeof window.Kisio.modes_img[mode] === 'undefined') {
    img = window.Kisio.modes_img[commercialModeId];
  }
  return img;
};

TransportTabContentComponent.prototype.renderSectionItem = function (config) {
  const item = document.createElement("div");
  item.classList.add(config.itemClass, "shared-item", "deletable");

  const header = document.createElement("div");
  header.classList.add("header");

  const iconSpan = document.createElement("span");
  iconSpan.classList.add("icon");
  if (config.icon) {
    iconSpan.classList.add(`icon_${config.type}`);
    const imgIcon = document.createElement("img");
    imgIcon.src = config.icon;
    imgIcon.alt = `_${config.type}`;
    iconSpan.appendChild(imgIcon);
  }

  const nameSpan = document.createElement("span");
  nameSpan.classList.add("name");
  nameSpan.textContent = config.name;

  header.appendChild(iconSpan);
  header.appendChild(nameSpan);

  const content = document.createElement("div");
  content.classList.add("content");

  const addressDiv = document.createElement("div");
  addressDiv.classList.add("address");
  addressDiv.textContent = config.address;

  content.appendChild(addressDiv);

  const loaderDiv = document.createElement("div");
  loaderDiv.classList.add("loader-content");

  let loadingImg = this.getLoadingElement();
  loaderDiv.appendChild(loadingImg);
  content.appendChild(loaderDiv);

  getPOIById(config.id)
    .catch(() => {
      content.removeChild(loaderDiv);
    })
    .then((response) => {
      let leftNumber = null;
      let rightNumber = null;
      let leftTotal = null;
      let rightTotal = null;

      let result = response.result;

      switch (true) {
        case config.poiType.includes("bicycle_rental"):

          rightNumber = response?.result?.pois[0]?.stands?.available_bikes;
          rightTotal = response?.result?.pois[0]?.stands?.total_stands;
          leftNumber = response?.result?.pois[0]?.stands?.available_places;
          leftTotal = response?.result?.pois[0]?.stands?.total_stands;
          break;
        case config.poiType.includes("amenity:parking"):
          rightNumber =  response?.result?.pois[0]?.car_park?.available_PRM;
          rightTotal = response?.result?.pois[0]?.car_park?.available_PRM + response?.result?.pois[0]?.car_park?.occupied_PRM;
          leftNumber = response?.result?.pois[0]?.car_park?.available;
          leftTotal = response?.result?.pois[0]?.car_park?.total_places - rightTotal;
          break;
      }

      if (
        typeof leftNumber === "undefined" ||
        typeof rightNumber === "undefined"
      ) {
        content.removeChild(loaderDiv);
        return;
      }

      if (config.type === "vls" || config.type === "parking") {
        const parentSlot = document.createElement('div');
        parentSlot.classList.add('parent-slot');

        const leftSlotNumber = document.createElement("span");
        leftSlotNumber.classList.add("number");

        if (config.leftSlotPictoClasses.length > 0) {
          config.leftSlotPictoClasses.forEach((classname) => {
            let dynamicIcon = document.createElement("i");
            dynamicIcon.classList.add(classname, "section_icon", "ikisio");
            leftSlotNumber.appendChild(dynamicIcon);
          });
        }
        leftSlotNumber.innerHTML += leftNumber + " " + config.leftSlotLabel;

        const leftSlotDiv = document.createElement("div");
        leftSlotDiv.classList.add("left-slot", config.leftSlotIconClass);
        leftSlotDiv.appendChild(leftSlotNumber);
        leftSlotDiv.classList.add(this[config.leftDeterminer].call(this, leftTotal, leftNumber));
        // leftSlotDiv.insertAdjacentText("beforeend", config.leftSlotLabel);

        const rightSlotNumber = document.createElement("span");
        rightSlotNumber.classList.add("number");

        if (config.rightSlotPictoClasses.length > 0) {
          config.rightSlotPictoClasses.forEach((classname) => {
            let circleDiv = document.createElement("div");
            circleDiv.classList.add("section_icon");
            let dynamicIcon = document.createElement("i");
            dynamicIcon.classList.add(classname, "ikisio");
            circleDiv.appendChild(dynamicIcon)
            rightSlotNumber.appendChild(circleDiv);
          });
        }

        rightSlotNumber.innerHTML += rightNumber + " " + config.rightSlotLabel;

        const rightSlotDiv = document.createElement("div");
        rightSlotDiv.classList.add("right-slot", config.rightSlotIconClass);
        rightSlotDiv.classList.add(this[config.rightDeterminer].call(this, rightTotal, rightNumber));
        rightSlotDiv.appendChild(rightSlotNumber);

        content.removeChild(loaderDiv);
        parentSlot.appendChild(leftSlotDiv);
        parentSlot.appendChild(rightSlotDiv);
        content.appendChild(parentSlot);
      }
    });

  const actions =  new ButtonDepartureArrival({
      lat: config.latitude,
      lng: config.longitude,
      address: config.address
  }).build();

  item.appendChild(header);
  item.appendChild(content);

  item.appendChild(actions);

  const rightDiv = new MenuComponent({
    id: config.id,
    componentUpdate: this.update.bind(this),
  });

  rightDiv.mount(header);

  return item;
};

TransportTabContentComponent.prototype.determineClassForRemainingPlaces = function(totalPlaces, remainingPlaces) {
  const ratio = remainingPlaces / totalPlaces;
  if(remainingPlaces === 0) {
    return "grey";
  } else if (ratio <= 1/3) {
    return "red";
  } else if (ratio <= 2/3) {
    return "orange";
  } else {
    return "green";
  }
}

TransportTabContentComponent.prototype.determineClassForUsedPlaces = function(totalPlaces, usedPlaces) {
  const ratio = usedPlaces / totalPlaces;

  if (ratio <= 1/3) {
    return "green";
  } else if (ratio <= 2/3) {
    return "orange";
  } else if(ratio < 1) {
    return "red";
  } else {
    return "green";
  }
}

TransportTabContentComponent.prototype.renderVlsStationsSection = function () {
  const section = document.createElement("section");
  section.classList.add("section-vls-stations");

  const title = document.createElement("h3");
  title.textContent = Translator.trans("bookmark.section.title.vls");

  let vls = this.bookmarkCollections.filter((bookmark) => {
    return (
      bookmark.type === "poi" &&

        bookmark.data.poiType.includes("bicycle_rental")
    );
  });

  section.appendChild(title);

  if (vls.length === 0) {
    section.style.display = "none";
    return section;
  }

  const container = document.createElement("div");
  container.classList.add("vls-stations-container");

  vls.forEach((vlsItem) => {
    let vlsStation1 = this.renderSectionItem({
      id: vlsItem.id,
      poiType: vlsItem.data.poiType,
      type: "vls",
      itemClass: "vls-item",
      name: vlsItem.data.name,
      address: vlsItem.data.address,
      icon: vlsItem.data.icon,
      leftSlotIconClass: "left-slot-icon",
      leftSlotPictoClasses: [
          "ikisio-parking"
      ],
      rightSlotIconClass: "right-slot-icon",
      rightSlotPictoClasses: [
          "ikisio-big-bicycle"
      ],
      leftDeterminer: 'determineClassForRemainingPlaces',
      rightDeterminer: 'determineClassForRemainingPlaces',
      leftSlotLabel: ` ${Translator.trans("bookmark.section.definition.places")}`,
      rightSlotLabel: ` ${Translator.trans("bookmark.section.definition.bikes")}`,
      latitude: vlsItem.data.latitude,
      longitude: vlsItem.data.longitude,
    });
    container.appendChild(vlsStation1);
  });

  section.appendChild(container);

  return section;
};

TransportTabContentComponent.prototype.renderParkingPRSection = function () {
  const section = document.createElement("section");
  section.classList.add("section-parking-pr");

  const title = document.createElement("h3");
  title.textContent = Translator.trans("bookmark.section.title.parking_P_R");

  section.appendChild(title);

  const container = document.createElement("div");
  container.classList.add("parking-pr-container");

  let parkingPr = this.bookmarkCollections.filter((bookmark) => {
    return (
      bookmark.type === "poi" &&
      bookmark.data.poiType.includes("amenity:parking")
    );
  });

  if (parkingPr.length === 0) {
    section.style.display = "none";
    return section;
  }
  let slotLibelle = Translator.trans("bookmark.section.definition.places");

  parkingPr.forEach((parkingPrItem) => {
    let parking1 = this.renderSectionItem({
      id: parkingPrItem.id,
      poiType: parkingPrItem.data.poiType,
      type: "parking",
      itemClass: "parking-item",
      name: parkingPrItem.data.name,
      address: parkingPrItem.data.address,
      icon: parkingPrItem.data.icon,
      leftSlotIconClass: "left-slot-icon",
      leftSlotPictoClasses: [
        "ikisio-parking",
      ],
      rightSlotIconClass: "orange-slot-icon",
      rightSlotPictoClasses: [
        "ikisio-handi",
      ],
      leftDeterminer: 'determineClassForRemainingPlaces',
      rightDeterminer: 'determineClassForRemainingPlaces',
      leftSlotLabel: slotLibelle,
      rightSlotLabel: slotLibelle,
      latitude: parkingPrItem.data.latitude,
      longitude: parkingPrItem.data.longitude,
    });
    container.appendChild(parking1);
  });

  section.appendChild(container);

  return section;
};

TransportTabContentComponent.prototype.getLoadingElement = function () {
  const loadingImg = document.createElement("img");
  loadingImg.classList.add("loader");
  loadingImg.src = `/bundles/canaltppnpcore/images/picto/16/loading.gif`;
  loadingImg.style.width = "20px";
  loadingImg.alt = "loading...";
  return loadingImg;
};

